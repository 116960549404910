import React from 'react'

export default function tab(props) {
  const { activeIndex, index, onClickHandler, children } = props
  const classNames = `tab-content__list-item ${activeIndex === index ? 'tab-content__list-item--active' : ''}`

  return (
    <li className={classNames}>
      {/* eslint-disable-next-line */}
      <a onClick={(e) => onClickHandler(e, index)}  href='#'>
        {children}
      </a>
    </li>
  )
}
