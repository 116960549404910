import React from 'react'
import Layout from '@components/layout'
import ValuesTabs from '@components/values-tabs'

export default function aboutUs(props) {
  const heroContent = 'WyeWorks was founded with a clear idea in mind: make software that makes an impact and that we can be proud of, work with an amazing group of people that we can learn from each other and call friends, and have fun while we are at it.'
  const railsCoreLink = 'https://weblog.rubyonrails.org/2010/9/9/santiago-pastorino-joins-rails-core/'

  return (
    <Layout location={props.location} title='About Us' namespace='about_us' description={heroContent}>
      <div className='hero hero-1'>
        <div className='hero-1__col-1'>
          <div className='title-label-1'>
            <div className='title-label-1__count'>
              04
            </div>
            <div className='title-label-1__seperator'></div>
            <div className='title-label-1__label'>
              About Us
            </div>
          </div>
          <h1 className='hero-1__title'>
            WE’RE A FAMILY THAT LOVES TO HAVE FUN
          </h1>
          <p className='hero-1__content'>
            {heroContent}
          </p>
        </div>
        <div className='hero-1__hero-image hero-1__hero-image--services'>
          <div className='hero-1__image-wrapper'>
            <div className='hero-1__image hero-1__image--services'>
              <img src='/images/table-tennis.jpg' alt='' />
            </div>
            <div className='hero-1__bg-pattern hero-1__bg-pattern--services'>
              <img src='/images/hero-services-pattern.png' alt='' />
            </div>
          </div>
        </div>
        <div className='hero-1__col-2'></div>
      </div>
      <section className='teamwork'>
        <div className='masonry__row-1'>
          <div className='masonry__content-block content-block-1'>
            <div className='content-block-1__title-label'>
              <h2 className='h2-label'>
                <div className='h2-label__title'>
                  Teamwork
                </div>
                <div className='h2-label__seperator'></div>
              </h2>
            </div>
            <div className='content-block-1__content'>
              <h3>
                We work together
              </h3>
              <p>
                We work together day to day to discover how to build our dream company, a place where we grow as people and professionals while generating a great impact on our clients’ path to success, and building relationships that we’ll keep for the rest of our lives.
              </p>
            </div>
          </div>
          <div className='masonry__block'></div>
        </div>
        <div className='masonry__row-2'>
          <div className='masonry__row-2__col-1'>
            <div className='masonry__block masonry__block--2'>
              <div className='masonry__image-container'>
                <div className='masonry__image'>
                  <img src='/images/about-relationships.jpg' alt='inspire relationships' />
                </div>
                <div className='masonry__pattern'>
                  <img src='/images/web-development1-pattern.png' alt='' />
                </div>
              </div>
              <h5 className='masonry__block-title'>
                We put people and relationships first
              </h5>
              <p className='masonry__copy'>
                To us, success is to inspire companies and individuals to build great software in a sustainable and joyful way, enabling businesses to flourish while people grow to become the better self. We focus on bringing value and developing people, rather than profits. This encourages us to keep investing in our team and our relationships, always thinking long-term.
              </p>
            </div>
            <div className='masonry__block masonry__block--4'>
              <div className='masonry__image-container'>
                <div className='masonry__image'>
                  <img src='/images/innovation1.jpg' alt='cross team collaboration' />
                </div>
                <div className='masonry__pattern'>
                  <img src='/images/web-development1-pattern.png' alt='' />
                </div>
              </div>
              <h5 className='masonry__block-title'>
                We excel in transparency and cross-team collaboration
              </h5>
              <p className='masonry__copy'>
                Cross-team collaboration is the backbone of our organization. We have a structure to support the least amount of processes possible, and flexible squads to tackle as many things as they are motivated to do. This requires a clear purpose and a high level of alignment, which we achieve through transparency.
              </p>
            </div>
          </div>
          <div className='masonry__row-2__col-2'>
            <div className='masonry__block masonry__block--1'>
              <div className='masonry__image-container'>
                <div className='masonry__image'>
                  <img src='/images/about-leaders.jpg' alt='passionate leaders' />
                </div>
                <div className='masonry__pattern'>
                  <img src='/images/process-consulting1-pattern.png' alt='' />
                </div>
              </div>
              <h5 className='masonry__block-title'>
                We're a team of passionate leaders
              </h5>
              <p className='masonry__copy'>
                We are a team of expert developers and coaches committed to make working with WyeWorks a transformative experience for both our clients and our team, so enriching and fun that other companies are inspired by us, by the type of leadership we live and breathe, as well as by the value we deliver.
              </p>
            </div>
            <div className='masonry__block masonry__block--3'>
              <div className='masonry__image-container'>
                <div className='masonry__image'>
                  <img src='/images/about-build.jpg' alt='' />
                </div>
                <div className='masonry__pattern'>
                  <img src='/images/innovation1-pattern.png' alt='high quality software' />
                </div>
              </div>
              <h5 className='masonry__block-title'>
                We build software that serves our customers with the highest quality
              </h5>
              <p className='masonry__copy'>
                We have worked for more than 10 years with startups in Silicon Valley and all over the US, from bootstrapped to VC funded providing high quality software and process consulting services and we have contributed to many Open Source projects such as Rails and Ember and actively supported the local and global tech events.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className='about-introduction'>
        <div className='content-block-1'>
          <div className='content-block-1__title-label'>
            <h2 className='h2-label'>
              <div className='h2-label__title'>
                Our Values
              </div>
              <div className='h2-label__seperator'></div>
            </h2>
          </div>
          <div className='content-block-1__content'>
            <h3>
              We build more than software, we build value
            </h3>
            <p>
              We bring value to our clients beyond our technical skills. Our values, strongly rooted in respect, honesty, letting go of the ego, trusting each other, and striving to do the best and get better each day serve as the foundation to perform with full commitment and joy.
            </p>
          </div>
        </div>
      </section>

      <ValuesTabs />

      <section>
        <div className='two-col-content two-col-content__timeline'>
          <div className='col-1'>
            <div className='content-block-2'>
              <div className='content-block-2__title-label'>
                <h2 className='h2-label'>
                  <div className='h2-label__title'>
                    Our Story
                  </div>
                  <div className='h2-label__seperator'></div>
                </h2>
              </div>
              <div className='content-block-2__content'>
                <h3>
                  All good things have a story... Here’s ours!
                </h3>
                <p>
                  WyeWorks’ story emerges from our love for programming and our desire to take it to another level, more professional and more personal, strongly based on our intuition and what we thought was right at every turn.
                </p>
                <p>
                  Our mindset has been always the same: good people doing a great job and building strong relationships above all. While our mindset stays the same, everything else evolves and it’s our goal to adapt and we embrace every challenge that comes ahead.
                </p>
              </div>
            </div>
          </div>
          <div className='col-2'>
            <ul className='timeline__list'>
              <li className='timeline__list-item'>
                <div className='timeline__year'>
                  2008
                </div>
                <div className='timeline__seperator'>
                  <div className='timeline__dot'></div>
                  <div className='timeline__line'></div>
                </div>
                <div className='timeline__info'>
                  <p>
                    Santiago and Jose co-founded WyeWorks with a clear idea in mind: make software that makes an impact and that we can be proud of, work with an amazing group of people that we can learn from each other, and enjoy while we are at it, using Ruby on Rails as the primary focus.
                  </p>
                </div>
              </li>
              <li className='timeline__list-item'>
                <div className='timeline__year'>
                  2010
                </div>
                <div className='timeline__seperator'>
                  <div className='timeline__dot'></div>
                  <div className='timeline__line'></div>
                </div>
                <div className='timeline__info'>
                  <p>
                    Santiago joins the&nbsp;
                    <a href={railsCoreLink} target='_blank' rel='noopener noreferrer'>
                      Rails Core team
                    </a>, who manages releases, evaluates pull-requests and does a lot of the groundwork on major new features for the framework.
                  </p>
                </div>
              </li>
              <li className='timeline__list-item'>
                <div className='timeline__year'>
                  2012
                </div>
                <div className='timeline__seperator'>
                  <div className='timeline__dot'></div>
                  <div className='timeline__line'></div>
                </div>
                <div className='timeline__info'>
                  <p>
                    After working with Wildfire for a couple of years, they got acquired by Google. WyeWorks was one of the few contractors that continued to work for Google for 2 more years.
                  </p>
                </div>
              </li>
              <li className='timeline__list-item'>
                <div className='timeline__year'>
                  2014
                </div>
                <div className='timeline__seperator'>
                  <div className='timeline__dot'></div>
                  <div className='timeline__line'></div>
                </div>
                <div className='timeline__info'>
                  <p>
                    We introduced Ember.js to CrazyEgg (now one of the biggest Ember apps out there) and started to specialize in Javascript and to experiment more with other technologies.
                  </p>
                </div>
              </li>
              <li className='timeline__list-item'>
                <div className='timeline__year'>
                  2016
                </div>
                <div className='timeline__seperator'>
                  <div className='timeline__dot'></div>
                  <div className='timeline__line'></div>
                </div>
                <div className='timeline__info'>
                  <p>
                    We were selected to be part of the&nbsp;
                    <a href='https://www.ops-conf.com' target='_blank' rel='noopener noreferrer'>
                      www.ops-conf.com
                    </a>,
                    a selection of 20 consultancy companies that share similar values and are committed to making a great impact in our industry.
                  </p>
                </div>
              </li>
              <li className='timeline__list-item'>
                <div className='timeline__year'>
                  2018
                </div>
                <div className='timeline__seperator'>
                  <div className='timeline__dot'></div>
                  <div className='timeline__line'></div>
                </div>
                <div className='timeline__info'>
                  <p>
                    After 10 years in business and more than 30 large projects, we moved to our 4th office and we are now 27 minds building our dream company, leading change in organizations through software written with love and care.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </Layout>
  )
}
