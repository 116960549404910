import React, { Component } from 'react'
import Slider from 'react-slick'
import Tab from '@components/tab'

export default class ValuesTabs extends Component {
  constructor(props) {
    super(props)

    this.state = {
      slideIndex: 0,
    }

    this.goToSlide = this.goToSlide.bind(this)
  }

  goToSlide(event, number) {
    event.preventDefault()
    this.slider.slickGoTo(number);
  }

  render() {
    const settings = {
      className: 'tab-content__image-wrapper',
      dots: false,
      fade: true,
      draggable: false,
      arrows: false,
      slidesToShow: 1,
      beforeChange: (current, next) => this.setState({ slideIndex: next })
    }
    return (
      <section className='tab-content value-tabs'>
        <div className='tab-content__container'>
          <Slider
            ref={slider => (this.slider = slider)}
            { ...settings }
          >
            <img className='tab-content__image' src='/images/about-confident-hero.jpg' alt='' />
            <img className='tab-content__image' src='/images/about-passionate-hero.jpg' alt='' />
            <img className='tab-content__image' src='/images/about-humble-hero.jpg' alt='' />
            <img className='tab-content__image' src='/images/about-kind-hero.jpg' alt='' />
          </Slider>
          <ul className='tab-content__list'>
            <Tab
              index={0}
              activeIndex={this.state.slideIndex}
              onClickHandler={this.goToSlide}
            >
              <div className='tab-content__wrapper'>
                <div className='tab-content__count'>
                  01
                </div>
                <div className='tab-content__icon'>
                  <div className='icon-cool-icon'></div>
                </div>
                <h5 className='tab-content__title'>
                  Be Confident
                </h5>
              </div>
              <div className='tab-content__wrapper tab-active__wrapper'>
                <div className='tab-content__count tab-active__count'>
                  01
                </div>
                <div className='tab-active__content-wrapper'>
                  <h5 className='tab-active__title'>
                    Be confident
                  </h5>
                  <div className='tab-active__content'>
                    Be confident in your competence but even more in your ability to learn beyond your skills you have now. Don't be afraid to ask questions and voice your dreams.
                  </div>
                </div>
              </div>
            </Tab>
            <Tab
              index={1}
              activeIndex={this.state.slideIndex}
              onClickHandler={this.goToSlide}
            >
              <div className='tab-content__wrapper'>
                <div className='tab-content__count'>
                  02
                </div>
                <div className='tab-content__icon'>
                  <div className='icon-heart-icon'></div>
                </div>
                <h5 className='tab-content__title'>
                  Be passionate
                </h5>
              </div>
              <div className='tab-content__wrapper tab-active__wrapper'>
                <div className='tab-content__count tab-active__count'>
                  02
                </div>
                <div className='tab-active__content-wrapper'>
                  <h5 className='tab-active__title'>
                    Be passionate
                  </h5>
                  <div className='tab-active__content'>
                    Find what gives you meaning and what you need to learn to grow. Engage emotionally and focus on your goals even when you are forced to stretch beyond your comfort zone.
                  </div>
                </div>
              </div>
            </Tab>
            <Tab
              index={2}
              activeIndex={this.state.slideIndex}
              onClickHandler={this.goToSlide}
            >
              <div className='tab-content__wrapper'>
                <div className='tab-content__count'>
                  03
                </div>
                <div className='tab-content__icon'>
                  <div className='icon-humble-icon'></div>
                </div>
                <h5 className='tab-content__title'>
                  Be humble
                </h5>
              </div>
              <div className='tab-content__wrapper tab-active__wrapper'>
                <div className='tab-content__count tab-active__count'>
                  03
                </div>
                <div className='tab-active__content-wrapper'>
                  <h5 className='tab-active__title'>
                    Be humble
                  </h5>
                  <div className='tab-active__content'>
                    Constantly strive to get better but pause to reflect and be satisfied with where you are now. Do not concern about status and work as a team to foster collective success.
                  </div>
                </div>
              </div>
            </Tab>
            <Tab
              index={3}
              activeIndex={this.state.slideIndex}
              onClickHandler={this.goToSlide}
            >
              <div className='tab-content__wrapper'>
                <div className='tab-content__count'>
                  04
                </div>
                <div className='tab-content__icon'>
                  <div className='icon-shield-icon'></div>
                </div>
                <h5 className='tab-content__title'>
                  Be kind
                </h5>
              </div>
              <div className='tab-content__wrapper tab-active__wrapper'>
                <div className='tab-content__count tab-active__count'>
                  04
                </div>
                <div className='tab-active__content-wrapper'>
                  <h5 className='tab-active__title'>
                    Be kind
                  </h5>
                  <div className='tab-active__content'>
                    Be yourself and genuinely care for others. Be courageous enough to tell the truth in a gentle way. Recognize everybody is unique. Be helpful.
                  </div>
                </div>
              </div>
            </Tab>
          </ul>
        </div>
      </section>
    )
  }
}
